import React, { FC, useEffect, useState } from 'react';
import { SortSelector } from '@/components/SortSelector';
import { Site } from '@/declarations/models/Site';
import { Tag } from '@/declarations/models/Tag';
import Layout from '../../Layout';
import M24FinderHeader from './M24FinderHeader';
import { MediaResourceType } from '../../../declarations/models/MediaResourceType';
import { ViewType } from '../../../declarations/ViewType';
import { GenericMedia } from '../../../declarations/GenericMedia';
import MediaList from '../../MediaList/MediaList';
import { Api } from '../../../services/Api';
import { useStore } from '../../store/Store';
import { M24MediaModel } from '../../../declarations/models/M24MediaModel';
import FinderLayout from '../FinderLayout';
import { toggleItem } from '../../../utils/array';
import SelectedM24MediaItem from './SelectedM24MediaItem';
import { SortDirection, SortOption, SortType } from '../../../declarations/models/SortOption';

export interface M24MediaFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<M24MediaModel>>) => void;
  defaultResourceType?: MediaResourceType;
  selectableResourceTypes?: Array<MediaResourceType>;
  multiSelect?: boolean;
}

const defaultSortOption: SortOption = {
  sortBy: SortType.UPDATED_AT,
  order: SortDirection.DESC,
};

export const M24MediaFinder: FC<M24MediaFinderProps> = ({
  open,
  onClose,
  onSelectionConfirmed,
  defaultResourceType = MediaResourceType.IMAGE,
  selectableResourceTypes,
  multiSelect = true,
}) => {
  const store = useStore();
  const site = store?.state?.selectedSite;
  const siteId = site?.id || 0;

  const [selectedMediaResourceType, setSelectedMediaResourceType] = useState<MediaResourceType>(defaultResourceType);
  const [mediaItems, setMediaItems] = useState<Array<GenericMedia<M24MediaModel>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });
  const [sortOption, setSortOption] = useState<SortOption>(defaultSortOption);
  const [searchQuery, _setSearchQuery] = useState<string>('');
  const setSearchQuery = (query: string) => {
    setPage((prevPage) => ({ ...prevPage, start: 0 }));
    _setSearchQuery(query);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshDep, setRefreshDep] = useState(Date.now());
  const [selectedSharedSite, setSelectedSharedSite] = useState<Site | null>(null);
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);

  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<M24MediaModel>>>([]);

  const _onClose = () => {
    onClose();
    setSelectedItems([]);
    setSelectedMediaResourceType(defaultResourceType);
    setSearchQuery('');
    setSortOption(defaultSortOption);
  };

  const isSelected = (item: GenericMedia<M24MediaModel>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<M24MediaModel>) => {
    if (multiSelect) {
      setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
    } else {
      setSelectedItems((previouslySelected) =>
        previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
      );
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleSelectionConfirmed: M24MediaFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getM24MediaResources(selectedSharedSite?.id ?? siteId, {
      ...page,
      order: sortOption.order,
      order_by: sortOption.sortBy,
      query: searchQuery,
      type: selectedMediaResourceType ?? undefined,
      tags: selectedTags?.map((t) => t.tag),
    });
    ctx
      .fetchDirect(null)
      .then((loadedPage) => {
        if (!unmounted) {
          setTotalCount(loadedPage?.count || 0);
          setMediaItems(
            (loadedPage?.items || []).map(
              (item) =>
                ({
                  id: String(item.id || 0),
                  title: item.title || item.filename,
                  filename: item.filename,
                  license: item.content?.license_code,
                  url: item.url,
                  mimetype: item.mimetype,
                  source: item,
                  type: item.type,
                } as GenericMedia<M24MediaModel>),
            ),
          );
        }
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [
    page,
    selectedSharedSite,
    selectedMediaResourceType,
    siteId,
    open,
    searchQuery,
    refreshDep,
    sortOption,
    selectedTags,
  ]);

  return (
    <FinderLayout<M24MediaModel>
      open={open}
      selectedItems={selectedItems}
      renderSelectedItem={(item) => <SelectedM24MediaItem item={item} onRemoveItem={handleSelectionChanged} />}
      onConfirmSelection={handleSelectionConfirmed}
      onClose={_onClose}>
      <Layout
        headerContent={
          <M24FinderHeader
            resourceType={selectedMediaResourceType}
            onResourceTypeChanged={setSelectedMediaResourceType}
            onQueryChange={setSearchQuery}
            selectedTags={selectedTags}
            onSelectedTagsChange={setSelectedTags}
            selectableResourceTypes={selectableResourceTypes}
            forceRefresh={() => setRefreshDep(Date.now())}
            siteId={siteId}
            site={site}
            selectedSharedSite={selectedSharedSite}
            onSelectSharedSite={setSelectedSharedSite}
          />
        }>
        <MediaList
          isLoading={isLoading}
          items={mediaItems}
          page={page}
          totalItemCount={totalCount}
          onPageChange={(c, start, rows) => handlePageChanged({ start, rows })}
          onPageSizeChange={(rows) => handlePageChanged({ rows })}
          defaultSelectedViewType={ViewType.GRID}
          getIsSelected={isSelected}
          onSelectChanged={handleSelectionChanged}
          isSelectable
          // TODO: Implement isFavourite-able
          isFavouriteable={false}
          customHeaderElement={
            <SortSelector
              availableOptions={[SortType.UPDATED_AT, SortType.CREATED_AT]}
              sortOption={sortOption}
              setSortOption={setSortOption}
            />
          }
          gridThumbnailWidth={200}
        />
      </Layout>
    </FinderLayout>
  );
};

export default M24MediaFinder;
