import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import Styles from '../../../assets/js/Styles';

interface GetImageProps {
  item: M24MediaModel;
  height: number;
  width: number;
}

export const GetImage: FC<GetImageProps> = ({ item, height, width }) => {
  // State and constants
  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const maxRetries = 15;
  const url = item?.url;

  // Unique ID for cache busting
  const uniqueId = useMemo(() => Date.now(), []);

  // Query parameters for image URL
  const imgMimeType = item?.mimetype?.includes('png') ? `mediaType=${item?.mimetype}` : '';
  const queryParamsArray = [imgMimeType, 'dimension=256x256', 'failhard=true', `bust=${retryCount}-${uniqueId}`];
  const queryParams = queryParamsArray.filter(Boolean).join('&');

  // Determine imgSrc based on resource type
  const imgSrc = useMemo(() => {
    if (
      item.type === MediaResourceType.IMAGE ||
      item.type === MediaResourceType.VIDEO ||
      item.type === MediaResourceType.ANIMATION
    ) {
      return `${url}?${queryParams}`;
    }
    return ''; // Empty src for non-image types
  }, [url, queryParams, item.type]);

  // Handle loading state when URL or resource type changes
  useEffect(() => {
    setRetryCount(0);
    if (
      item.type === MediaResourceType.IMAGE ||
      item.type === MediaResourceType.VIDEO ||
      item.type === MediaResourceType.ANIMATION
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [url, item.type]);

  // Image loading effect for image types
  useEffect(() => {
    if (
      item.type === MediaResourceType.IMAGE ||
      item.type === MediaResourceType.VIDEO ||
      item.type === MediaResourceType.ANIMATION
    ) {
      setLoading(true);

      const img = new Image();
      img.src = imgSrc;

      const handleLoad = () => {
        setLoading(false);
      };

      const handleError = () => {
        // Error handling if needed
      };

      img.addEventListener('load', handleLoad);
      img.addEventListener('error', handleError);

      if (img.complete) {
        setLoading(false);
      }

      return () => {
        img.removeEventListener('load', handleLoad);
        img.removeEventListener('error', handleError);
      };
    }
    setLoading(false);
  }, [imgSrc, item.type]);

  // Handle image error and retries
  const handleImageError = useCallback(() => {
    setTimeout(() => {
      setRetryCount((r) => {
        if (r < maxRetries) {
          return r + 1;
        }
        setLoading(false);
        return r;
      });
    }, 3000);
  }, [maxRetries]);

  // Helper function to get file extension
  function getFileExtension(filename: string) {
    const extension = filename.split('.').pop();
    return extension ? `.${extension}` : '';
  }

  // Styling constants
  const ICON_IMAGE_STYLE = {
    width: '64px',
    height: '64px',
    margin: `${(height - 64) / 2}px ${(width - 64) / 2}px`,
  };

  const ICON_WRAPPER: React.CSSProperties = {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '64px',
    height: '50px',
    backgroundColor: Styles.Colors.LIGHT_GREY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottomLeftRadius: '4px',
  };

  // Render based on media resource type
  switch (item.type) {
    case MediaResourceType.IMAGE:
    case MediaResourceType.ANIMATION:
    case MediaResourceType.VIDEO:
      return (
        <div style={{ position: 'relative', width, height }}>
          {loading && (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
              }}
            />
          )}
          {item.type === MediaResourceType.VIDEO && (
            <div style={ICON_WRAPPER}>
              <MaterialSymbol name='movie' fill />
              {getFileExtension(item.filename)}
            </div>
          )}
          <img
            src={imgSrc}
            title={item.filename}
            alt={item.content?.alttext}
            loading='lazy'
            crossOrigin='anonymous'
            style={{
              width: `${width}px`,
              height: `${height}px`,
              objectFit: 'contain',
              background: Styles.Colors.MEDIUM_DARK_GREY,
              overflow: 'hidden',
              flex: 1,
              visibility: loading ? 'hidden' : 'visible',
            }}
            onError={handleImageError}
            onLoad={() => setLoading(false)}
          />
        </div>
      );
    case MediaResourceType.AUDIO:
    case MediaResourceType.DOCUMENT:
      return (
        <div style={{ position: 'relative', width, height }}>
          <div style={ICON_WRAPPER}>
            <MaterialSymbol name={item.type === MediaResourceType.AUDIO ? 'music_note' : 'description'} fill />
            {getFileExtension(item.filename)}
          </div>
          {/* Use a div instead of img for non-image types */}
          <div
            title={item.filename}
            style={{
              width: `${width}px`,
              height: `${height}px`,
              objectFit: 'contain',
              background: Styles.Colors.MEDIUM_DARK_GREY,
              overflow: 'hidden',
              flex: 1,
            }}
          />
        </div>
      );
    default:
      return <MaterialSymbol name='image' sx={ICON_IMAGE_STYLE} />;
  }
};

export default GetImage;
