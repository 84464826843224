import React, { FC } from 'react';
import { ImageListItemBar } from '@mui/material';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { GenericMedia } from '@/declarations/GenericMedia';
import { CommonMediaListViewTypeProps } from '../../CommonMediaListViewTypeProps';
import Styles from '../../../../assets/js/Styles';
import FavouriteButton from '../../../FavouriteButton';
import Image from '../../../Image';

export interface GridViewDefaultVariantProps extends Omit<CommonMediaListViewTypeProps, 'items'> {
  item: GenericMedia;
  isFavourite: boolean;
}

export const GridViewDefaultVariant: FC<GridViewDefaultVariantProps> = ({
  item,
  isFavourite,
  onFavouriteChanged,
  isFavouriteable,
}) => {
  let imgMimeType = '';
  if (item?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item?.mimetype}`;
  }

  const ICON_WRAPPER: React.CSSProperties = {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '50px',
    height: '50px',
    backgroundColor: Styles.Colors.LIGHT_GREY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottomLeftRadius: '4px',
  };

  // Helper function to get file extension
  function getFileExtension(filename: string) {
    const extension = filename.split('.').pop();
    return extension ? `.${extension}` : '';
  }

  const renderIcon = () => {
    switch (item.type) {
      case MediaResourceType.VIDEO:
        return <MaterialSymbol name='movie' fill />;
      case MediaResourceType.AUDIO:
        return <MaterialSymbol name='music_note' fill />;
      case MediaResourceType.DOCUMENT:
        return <MaterialSymbol name='description' fill />;
      default:
        return null;
    }
  };

  return (
    <>
      {(item.type === MediaResourceType.VIDEO ||
        item.type === MediaResourceType.AUDIO ||
        item.type === MediaResourceType.DOCUMENT) && (
        <div style={ICON_WRAPPER}>
          {renderIcon()}
          {item?.filename && getFileExtension(item.filename)}
        </div>
      )}
      <Image
        src={`${item.url}?dimension=250x250&${imgMimeType}`}
        alt={item.title}
        loading='lazy'
        sx={{
          width: '200px',
          height: '200px',
          objectFit: 'contain',
          background: '#333',
          overflow: 'hidden',
          flex: 1,
        }}
      />
      <ImageListItemBar
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          color: Styles.Colors.WHITE,
          height: '33%',
          width: '100%',
          background: 'linear-gradient(to top, #000B, #0003 76%, transparent)',
        }}
        title={item.title}
        subtitle={item.description}
        actionIcon={
          isFavouriteable && (
            <FavouriteButton
              isFavourite={isFavourite}
              onChange={(e) => {
                e.stopPropagation();
                onFavouriteChanged?.(item, !isFavourite);
              }}
              color='inherit'
              selectedColor='inherit'
            />
          )
        }
        position='bottom'
        actionPosition='left'
      />
    </>
  );
};

export default GridViewDefaultVariant;
